const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.vale.forwoodsafety.com',
    APP_CLIENT_ID: '5mb3i05fsfnfe9d25k093aloio',
    USER_TOKEN_URL: 'https://9ktnk0nfyd.execute-api.us-east-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://ti6ue2t5q6.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.vale.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.vale.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://ytsci10m9h.execute-api.us-east-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;